<template>
  <!-- create dialog using vuetify for data entry -->
  <v-dialog width="90%" :value="value" @input="$emit('input', $event)">
    <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation
    >
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>
            {{ $t("hr.loan.title") }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col md="4">
              <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.date')" type="date"
                            outlined
                            v-model="form.document.date">
              </v-text-field>
            </v-col>
            <v-col md="4">
              <v-autocomplete :items="cashs" item-text="name" item-value="id" @update:search-input="handleSearchCashs"
                              :disabled="readMode" :label="$t('hr.loan.cash')"
                               :rules="requiredRule()" outlined
                              v-model="form.cash_account_id">
              </v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-text-field :disabled="readMode"  :label="$t('hr.vacation.note')" outlined
                            v-model="form.document.note">
              </v-text-field>
            </v-col>
          </v-row>
          <template v-for="(emp, idx) in form.loans">
            <v-row class="no-padding">
              <v-col>
                <v-select :disabled="readMode" :items="employees" item-text="name" item-value="id"
                          :rules="requiredRule()" :label="$t('hr.time.emp-name')"
                          outlined v-model="form.loans[idx].employee_id">
                </v-select>
              </v-col>
              <v-col>
                <v-select :items="loans" item-text="name" item-value="id" :disabled="readMode" :rules="requiredRule()"
                          :label="$t('hr.loan.type')" outlined
                          v-model="form.loans[idx].loan_type_id">
                </v-select>
              </v-col>
              <v-col>
                <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.variation.value')" outlined
                              v-model="form.loans[idx].value">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.loan.num-of-trans')" outlined
                              v-model="form.loans[idx].payments_number">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.loan.trans-value')" outlined
                              v-model="form.loans[idx].payment">
                </v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete :items="form.loans[idx].accounts" @update:search-input="handleSearch($event, idx)" item-text="name"
                                item-value="id" :disabled="readMode" :rules="requiredRule()"
                                :label="$t('hr.loan.loan-value')" outlined
                                v-model="form.loans[idx].account_id">
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field :disabled="readMode" :label="$t('hr.time.date')" outlined
                              type="date"
                              v-model="form.loans[idx].given_date">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field :disabled="readMode" :label="$t('hr.loan.stop-date')" outlined type="date"
                              v-model="form.loans[idx].stop_date">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field :disabled="readMode" :label="$t('hr.vacation.note')" outlined
                              v-model="form.loans[idx].note">
                </v-text-field>
              </v-col>
            </v-row>
            <br>
            <v-divider></v-divider>
            <br>
          </template>
          <v-btn type="button"
                 @click="addAnotherEmployee">{{ $t('add') }}
          </v-btn>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green"
            class="white--text"
            depressed
            type="submit"
          >{{ $t("submit") }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            depressed
            @click="$emit('input', false)"
          >{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import {requiredRule} from "@/helpers/validation-rules";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'LoadModal',
  data() {
    return {
      valid: false,
      form: {
        document: {
          name: 'leave',
          date: '',
          note: '',
        },
        cash_account_id: '',
        loans: [
          {
            loan_type_id: '',
            value: '',
            account_id: '',
            accounts: [],
            given_date: '',
            employee_id: '',
            payments_number: '',
            stop_date: '',
            payment_value: '',
            note: ''
          }
        ],
      }
    }
  },
  props: {
    readMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.fetchLoans()
    this.fetchEmployees()
    //this.fetchAccounts()
  },
  methods: {
    ...mapActions({
      store: 'loans/store',
      update: 'loans/update',
      fetchLoans: 'employees/fetchLoans',
      fetchEmployees: 'employees/fetchEmployees',
      fetchAccounts: 'employees/fetchAccounts',
      fetchCashs: 'employees/fetchCashs',
    }),
    addAnotherEmployee() {
      this.form.loans.push({
        loan_type_id: '',
        value: '',
        account_id: '',
        accounts: [],
        given_date: '',
        employee_id: '',
        payments_number: '',
        stop_date: '',
        payment_value: '',
        note: ''
      })
    },
    async handleSearch(search, idx) {
      if (search) {
        this.form.loans[idx].accounts = await this.fetchAccounts(search)
        console.log(this.loans[idx].accounts)
      }
    },
    handleSearchCashs(search) {
      if (search)
        this.fetchCashs(search)
    },
    requiredRule() {
      return requiredRule
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {
        await this[this.item ? 'update' : 'store'](this.form)
        this.$emit('input', false)
      }
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employees/employees',
      loans: 'employees/loans',
      accounts: 'employees/accounts',
      cashs: 'employees/cashs'
    })
  },

  watch: {
    item(nV) {
      this.handleSearchCashs(nV?.cash_account?.name)
      this.form = {...this.form, ...nV, document: {...nV}}
      this.form.loans.forEach(async (el) => {
        el.accounts = await this.fetchAccounts(el.account.name)
      })
    }
  }

}
</script>

<style scoped>
.no-padding >>> div.col {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
</style>
